/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
import dotenv from 'dotenv'

dotenv.config()

export default class Configuration {
  static get CONFIG(): any {
    return {
      API_BASE_URL: '$VUE_APP_API_BASE_URL',
      API_BASE_PATH: '$VUE_APP_API_BASE_PATH',
      API_REPORTING: '$VUE_APP_API_REPORTING',
      API_URL_HJX: '$VUE_APP_API_URL_HJX',
      VERSION: '1.5.1',
      HYDRA_API: '$VUE_APP_HYDRA_API',
      HYDRA_CLIENT_ID: '$VUE_APP_HYDRA_CLIENT_ID',
      HYDRA_SCOPE: '$VUE_APP_HYDRA_SCOPE',
      HYDRA_RESPONSE_TYPE: '$VUE_APP_HYDRA_RESPONSE_TYPE',
      HYDRA_STATE: '$VUE_APP_HYDRA_STATE',
      HYDRA_CLIENT_SECRET: '$VUE_APP_HYDRA_CLIENT_SECRET',
      HYDRA_GRANT_TYPE: '$VUE_APP_HYDRA_GRANT_TYPE',
      OTP_TOKEN_BASE_URL: '$VUE_APP_OTP_TOKEN_BASE_URL',
      OTP_BASE_URL: '$VUE_APP_OTP_BASE_URL',
      OTP_GRANT_TYPE: '$VUE_APP_OTP_GRANT_TYPE',
      OTP_SCOPE: '$VUE_APP_OTP_SCOPE',
      OTP_CLIENT_ID: '$VUE_APP_OTP_CLIENT_ID',
      OTP_CLIENT_SECRET: '$VUE_APP_OTP_CLIENT_SECRET',
      USERNAME_MQTT: '$VUE_APP_USERNAME_MQTT',
      PASSWORD_MQTT: '$VUE_APP_PASSWORD_MQTT'
    }
  }

  static value(name: any) {
    if (!(name in this.CONFIG)) {
      console.log(`Configuration: There is no key named "${name}"`)
      return
    }

    const value = this.CONFIG[name]

    if (!value) {
      console.log(`Configuration: Value for "${name}" is not defined`)
      return
    }

    if (value.startsWith('$VUE_APP_')) {
      // value was not replaced, it seems we are in development.
      // Remove $ and get current value from process.env
      const envName = value.substr(1)
      const envValue = process.env[envName]
      if (envValue) {
        return envValue
      } else {
        console.log(`Configuration: Environment variable "${envName}" is not defined`)
      }
    } else {
      // value was already replaced, it seems we are in production.
      return value
    }
  }
}
